import React from "react"
import { graphql } from "gatsby"

// import app components
import PageWrapper from "../../../../components/pageWrapper"
import { Archive } from "../../../../features/openDatasets"
import Seo from "../../../../components/seo"

export const Head = props => <Seo {...props} />

const Template = props => {
  const {
    data: {
      wpPage: {
        template: { acf },
      },
      allWpOpendataset: { nodes: posts },
    },
    pageContext: { pagination },
  } = props

  return (
    <PageWrapper {...props}>
      <Archive {...acf} posts={posts} pagination={pagination} />
    </PageWrapper>
  )
}

export const Query = graphql`
  query OpenDatasetArchive($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      template {
        ... on WpTemplate_ArchiveOpendataset {
          templateName
          acf {
            fieldGroupName
            banner {
              fieldGroupName
              headline
              image {
                desktop {
                  altText
                  gatsbyImage(width: 1920)
                  svg
                }
                mobile {
                  altText
                  gatsbyImage(width: 600)
                  svg
                }
              }
            }
            newsletter {
              buttontext
              headline
              fieldGroupName
            }
          }
        }
      }
    }
    allWpOpendataset {
      nodes {
        id
        title
        uri
        date
        featuredImage {
          node {
            altText
            gatsbyImage(width: 400)
          }
        }
        acf {
          excerpt
        }
      }
    }
  }
`

export default Template
