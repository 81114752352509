import React from "react"
import { graphql } from "gatsby"

// import app components
import PageWrapper from "../../../../components/pageWrapper"
import { Single } from "../../../../features/blog"
import Seo from "../../../../components/seo"

export const Head = props => <Seo {...props} />

const Template = props => {
  const {
    pageContext: { themeOptions },
    data: {
      allWpPost: { nodes: relatedPosts },
      wpPost: { id, title, featuredImage, acf },
    },
  } = props

  return (
    <PageWrapper {...props}>
      <Single
        title={title}
        featuredImage={featuredImage?.node || featuredImage}
        {...acf}
        relatedPosts={relatedPosts
          .filter(o => o.id !== id)
          .filter((o, i) => i < 3)}
      />
    </PageWrapper>
  )
}

export const Query = graphql`
  query PostDefault($id: String!) {
    wpPost(id: { eq: $id }) {
      id: databaseId
      title
      featuredImage {
        node {
          altText
          gatsbyImage(width: 400)
        }
      }
      acf {
        date
        author
        flex {
          ... on WpPost_Acf_Flex_Headline {
            color
            fieldGroupName
            headline
            variant
          }
          ... on WpPost_Acf_Flex_Code {
            text
            fieldGroupName
          }
          ... on WpPost_Acf_Flex_Content {
            fieldGroupName
            text
          }
          ... on WpPost_Acf_Flex_Video {
            fieldGroupName
            video {
              fieldGroupName
              host
              id
            }
          }
          ... on WpPost_Acf_Flex_Form {
            fieldGroupName
            buttontext
            fieldGroupName
            form
            headline
            text
          }
          ... on WpPost_Acf_Flex_Images {
            caption
            columnsdesktop
            columnsmobile
            fieldGroupName
            images {
              desktop {
                altText
                gatsbyImage(width: 1024)
                svg
              }
              mobile {
                altText
                gatsbyImage(width: 400)
                svg
              }
            }
          }
        }
      }
    }
    allWpPost(limit: 4) {
      nodes {
        id: databaseId
        title
        uri
        featuredImage {
          node {
            altText
            gatsbyImage(width: 400)
          }
        }
      }
    }
  }
`

export default Template
