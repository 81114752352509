import React from "react"
import Parser from "html-react-parser"

export const SEO = props => {
  const {
    pageContext: { seo, siteTitle = "Parallel Domain" },
  } = props

  return (
    <>
      <title>{`${Parser(seo.title || "")} - ${siteTitle}`}</title>
      <meta name="description" content={`${Parser(seo.metaDesc || "")}`} />

      <meta name="robots" content={seo?.metaRobotsNoindex || "index"} />

      <meta
        property="og:description"
        content={`${Parser(seo.metaDesc || "")}`}
      />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={seo.opengraphImage?.sourceUrl || ""} />
      <meta property="og:site_name" content={`${Parser(siteTitle || "")}`} />
      <meta property="og:title" content={`${Parser(seo.title || "")}`} />

      <meta property="twitter:card" content="summary" />
      <meta
        property="twitter:image"
        content={seo.opengraphImage?.sourceUrl || ""}
      />
      <meta property="twitter:title" content={`${Parser(seo.title || "")}`} />
    </>
  )
}

export default SEO
